import React, { useEffect } from 'react';
import Layout from '../components/layout';
import { PATHS } from '../config/urls';
import { Link } from 'gatsby';
import AOS from 'aos';

import { BoldText } from '../styles/globals/styles';

import { Content, CustomButton, Container, Text, Number } from '../styles/pages/404';

const Error = () => {
  useEffect(
    () => {
      AOS.init({
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS,
  );

  return (
    <Layout>
      <Container>
        <Content data-aos="fade">
          <Number>404</Number>
          <Text>
            Looks like the page you are looking for doesn&apos;t exist. <br />
            <BoldText>Please try with another one.</BoldText>
          </Text>
          <Link to={PATHS.INDEX}>
            <CustomButton> Back To Home </CustomButton>
          </Link>
        </Content>
      </Container>
    </Layout>
  );
};

export default Error;
