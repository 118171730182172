import styled from 'styled-components';

import Hero1920 from '../../images/404-1920.png';
import HeroImage from '../../images/404-1366.png';
import HeroMobile from '../../images/404-mobile.png';

import { TABLET_768 } from '../globals/sizes';
import { COLORS } from '../globals/colors';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  background-image: url(${Hero1920});
  background-size: cover;

  @media (max-width: 1366px) {
    background-image: url(${HeroImage});
  }

  @media (max-width: ${TABLET_768}px) {
    background-image: url(${HeroMobile});
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Number = styled.div`
  font-weight: 750;
  color: ${COLORS.vermilion};
  font-size: 80px;
`;

export const Text = styled.div`
  display: flex;
  text-align: center;
  padding-top: 50px;
  font-weight: 300;
`;

export const CustomButton = styled.button`
  background-color: ${COLORS.vermilion};
  border-radius: 25px;
  opacity: 1;
  padding: 14px 40px 16px 42px;
  border: 0;
  color: ${COLORS.white};
  transition: 1s;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin-top: 40px;

  :hover {
    background-color: ${COLORS.grayHover};
    color: ${COLORS.white};
  }
`;
